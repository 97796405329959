import { useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditCoachFormData } from 'src/api/form-types';
import putCoachUpdate from 'src/api/put-update-coach';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CoachForm from 'src/components/coach/CoachForm';
import { IRootState } from 'src/store';
import { useSelector } from 'react-redux';

export default function CoachProfile () {
  const coachId = useSelector((state: IRootState) => state.authCoach.coachId);
  const { t } = useTranslation('coach');
  const [dialogOpen, setDialogOpen] = useState(false);

  const submitAction = async (data: EditCoachFormData) => {
    const res = await putCoachUpdate(data, coachId, true);
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Container sx={{ padding: 2 }}>
      <Typography variant='h6' color='primary.main' textAlign='center'
        sx={{ marginBottom: 1.5 }}>
        {t('add_coach.profile.title')}
      </Typography>
      
      <CoachForm isCoachProfile submitAction={submitAction} updateCoachId={coachId} />

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('add_coach.success.update_coach')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('add_coach.btn.go_to_coach')}
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  )
}