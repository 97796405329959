import { useMemo } from 'react';
import { Container, Fab, Grid, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { theme } from 'src/styles/muiTheme';
import { appBarHeightLarge, appBarHeightSmall } from 'src/components/WebAppBar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAdminDirectorAllCoach from 'src/hooks/swr/use-admin-director-all-coach';
import CoachBox from 'src/components/CoachBox';
import SalaryExportExcelBtn from 'src/components/admin/SalaryExportExcelBtn';
import ClientClassExportExcelBtn from 'src/components/admin/ClientClassExportExcelBtn';
import { nameToLowercase } from 'src/utils/clean-name';
import ClientTransactionExportExcelBtn from 'src/components/admin/ClientTransactionExportExcelBtn';
import ClientDataExportExcelBtn from 'src/components/admin/ClientDataExportExcelBtn';

const fabMediumHeight = 48;
const fabBottomDistance = 10;

interface AdminDirectorCoachManagementProps {
  isAdmin?: boolean,
}

export default function AdminDirectorCoachManagement ({
  isAdmin = false,
}: AdminDirectorCoachManagementProps) {
  const { t } = useTranslation('coach');
  const { allCoach } = useAdminDirectorAllCoach();

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const appBarHeight = useMemo(() => (isSmUp ? appBarHeightLarge : appBarHeightSmall), [isSmUp]);
  
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        padding: 2,
        position: 'relative',
        minHeight: `calc(100vh - ${appBarHeight}px)`,
        paddingBottom: (fabBottomDistance + fabMediumHeight) / 10 + 1,
      }}>

      {isAdmin &&
        <Grid container
          spacing={1}
          direction='column'
          sx={{ marginBottom: 2 }}>
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {t('data_stat.title.client_class')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {t('data_stat.text.download', {type: nameToLowercase(t('data_stat.title.client_class'))})}
            </Typography>
          </Grid>
          <Grid item>
            <ClientClassExportExcelBtn isAllData />
          </Grid>
          {/* hardcoded each month for now */}
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2024 })}
            </Typography>
          </Grid>

          <Grid
            item container
            spacing={1}>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={1} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={2} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={3} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={4} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={5} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={6} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={7} />
            </Grid>
            {/* <Grid item>
              <ClientClassExportExcelBtn year={2024} month={8} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={9} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={10} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={11} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2024} month={12} />
            </Grid> */}
          </Grid>

          {/* hardcoded each month for now */}
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2023 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={1} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={2} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={3} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={4} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={5} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={6} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={7} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={8} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={9} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={10} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={11} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2023} month={12} />
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2022 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <ClientClassExportExcelBtn year={2022} month={8} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2022} month={9} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2022} month={10} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2022} month={11} />
            </Grid>
            <Grid item>
              <ClientClassExportExcelBtn year={2022} month={12} />
            </Grid>
          </Grid>
        </Grid>
      }

      {isAdmin &&
        <Grid container
          spacing={1}
          direction='column'
          sx={{
            marginBottom: 2,
          }}>
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {t('coach_man.subtitle.salary')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {t('coach_man.salary.download')}
            </Typography>
          </Grid>

          {/* hardcoded each month for now */}
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2024 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={1} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={2} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={3} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={4} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={5} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={6} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={7} />
            </Grid>
            {/* <Grid item>
              <SalaryExportExcelBtn year={2024} month={8} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={9} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={10} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={11} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2024} month={12} />
            </Grid> */}
          </Grid>
          
          {/* hardcoded each month for now */}
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2023 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={1} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={2} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={3} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={4} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={5} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={6} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={7} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={8} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={9} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={10} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={11} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2023} month={12} />
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2022 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <SalaryExportExcelBtn year={2022} month={8} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2022} month={9} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2022} month={10} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2022} month={11} />
            </Grid>
            <Grid item>
              <SalaryExportExcelBtn year={2022} month={12} />
            </Grid>
          </Grid>
        </Grid>
      }

      {isAdmin &&
        <Grid container
          spacing={1}
          direction='column'
          sx={{
            marginBottom: 2,
          }}>
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {t('data_stat.title.transaction_record')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {t('data_stat.text.download', {type: nameToLowercase(t('data_stat.title.transaction_record'))})}
            </Typography>
          </Grid>
          <Grid item>
            <ClientTransactionExportExcelBtn isAllData />
          </Grid>

          {/* hardcoded each month for now */}
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2024 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={1} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={2} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={3} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={4} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={5} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={6} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={7} />
            </Grid>
            {/* <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={8} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={9} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2024} month={10} />
            </Grid> */}
          </Grid>
          
          {/* hardcoded each month for now */}
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2023 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={1} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={2} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={3} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={4} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={5} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={6} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={7} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={8} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={9} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={10} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={11} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2023} month={12} />
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('coach_man.salary.year', { year: 2022 })}
            </Typography>
          </Grid>
          <Grid
            item container
            spacing={1}>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2022} month={8} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2022} month={9} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2022} month={10} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2022} month={11} />
            </Grid>
            <Grid item>
              <ClientTransactionExportExcelBtn year={2022} month={12} />
            </Grid>
          </Grid>
        </Grid>
      }

      {isAdmin &&
        <Grid container
          spacing={1}
          direction='column'
          sx={{
            marginBottom: 2,
          }}>
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {t('data_stat.title.client_data')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {t('data_stat.text.download', {type: nameToLowercase(t('data_stat.title.client_data'))})}
            </Typography>
          </Grid>

          <Grid item>
            <ClientDataExportExcelBtn isAllData />
          </Grid>
        </Grid>
      }

      {!isAdmin &&
        <Typography variant='h6' color='primary.main'>
          {t('coach_man.title')}
        </Typography>
      }
      {isAdmin &&
        <Typography variant='h6' color='primary.main'>
          {t('coach_man.subtitle.data')}
        </Typography>
      }

      {allCoach && allCoach.map((coach) => (
        <CoachBox
          coach={coach} key={`coach-${coach.id}`}
          to={`/coach/${isAdmin ? 'admin' : 'director'}/coach-management/coach/${coach.id}`}
          />
      ))}

      {!isAdmin &&
        <Fab color='primary' aria-label='add' size='medium'
          sx={{
            position: 'fixed',
            bottom: fabBottomDistance,
            right: fabBottomDistance,
            cursor: 'pointer',
          }}
          onClick={() => navigate('/coach/director/add-coach')}>
          <AddIcon />
        </Fab>
      }

    </Container>
  )
}