import { EditCoachFormData } from 'src/api/form-types';

export default function putCoachUpdate(data: EditCoachFormData, coachId: number, isCoachProfile: boolean = false) {
  return fetch(`${global.API_v1}/${isCoachProfile ? 'coach/' : 'director/'}coach/single`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data, coachId: coachId }),
  });
}
